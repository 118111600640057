<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="8" lg="9" md="10" sm="12" class="mx-auto">
        <v-row class="mt-1">
          <v-col cols="12" xl="10" lg="9" md="8" sm="12">
            <v-text-field
              v-model="keywords"
              label="关键词"
              append-outer-icon="mdi-magnify"
              @click:append-outer="on_click_search"
              @keydown.enter="on_click_search"
            ></v-text-field>

            <v-btn
              v-if="login_ok == true"
              x-small
              class="mb-4"
              fab
              depressed
              color="secondary"
              link
              to="/blog/post/new"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>

            <v-card
              v-for="item in posts"
              :key="item.id"
              elevation="0"
              rounded="lg"
              class="mb-4"
              link
              :to="'/blog/post/' + item.id"
            >
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-subtitle>{{
                item.creation_time.substr(0, 10)
              }}</v-card-subtitle>
              <v-card-text
                class="post-content"
                v-html="formated_content(item.content)"
              >
              </v-card-text>
            </v-card>

            <v-row class="my-2 mx-2" v-if="show_page_info == true">
              <v-spacer></v-spacer>

              <v-btn x-small class="mb-4 ml-2" fab depressed plain @click="on_click_page_backward">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-chip class="mb-4 ml-2" outlined> 第 {{ page_number }} / {{ total_page_number }} 页 </v-chip>
              <v-btn x-small class="mb-4 ml-2" fab depressed plain @click="on_click_page_forward">
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>

          <v-col cols="12" xl="2" lg="3" md="4" sm="12" class="mt-1 pr-1">
            <v-chip-group column>
              <v-chip
                v-for="item in tags"
                :key="item.id"
                elevation="0"
                rounded="lg"
                class="mb-4"
                link
                @click="on_click_tag(item)"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["login_ok"],

  data: () => ({
    // login_ok: true,
    posts: [
      // {
      //   id: 1,
      //   title: "hello",
      //   content: "world",
      // },
    ],
    tags: [
      // {
      //   id: 1,
      //   name: "未分类",
      // },
    ],
    keywords: "", // 搜索关键词
    page_number: 1, // 当前显示的是第几页
    total_page_number: 1, // 总共有几页
    show_page_info: true, // 是否显示页码信息
  }),
  methods: {
    get_all_tags() {
      axios.post("/backend/tags/all").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.tags = response.data.data.tags;
        } else {
          console.log("找不到标签");
        }
      });
    },
    on_click_tag(item) {
      this.show_page_info = false;
      axios.get("/backend/posts/with/tag/" + item.id).then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.posts = response.data.data.posts;
          if (this.posts != null && this.posts.length > 0) {
            this.total_page_number = 1;
            this.page_number = 1;
            return;
          }
        }
        console.log("找不到文章");
        this.posts = [];
        this.total_page_number = 0;
        this.page_number = 0;
      });
    },
    on_click_search() {
      this.show_page_info = true;
      axios
        .post("/backend/posts/select", {
          keywords: this.keywords,
          page_number: this.page_number,
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            this.posts = response.data.data.posts;
            this.page_number = response.data.data.page_number;
            this.total_page_number = response.data.data.total_page_number;
          } else {
            console.log("找不到文章");
            this.posts = [];
          }
        });
    },
    on_click_page_backward() {
      if (this.page_number > 1) {
        this.page_number -= 1;
      } else {
        this.page_number = 1;
      }
      this.on_click_search();
    },
    on_click_page_forward() {
      if (this.page_number < this.total_page_number) {
        this.page_number += 1;
      } else {
        this.page_number = this.total_page_number;
      }
      this.on_click_search();
    },
    formated_content(content) {
      if (content != null) {
        content = content.substring(0, 16) + " ..";
        return content.replace(/\n/g, "<br/>");
      }
      return "";
    },
  },
  mounted() {
    this.get_all_tags();
    this.on_click_search();
  },
};
</script>

<style scoped>
.post-content >>> img,
p,
span {
  max-width: 100%;
}

.post-content >>> pre {
  overflow: auto;
  background-color: whitesmoke;
  border-left: 6px solid grey;
  padding: 10px;
}
</style>