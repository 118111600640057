import { JSEncrypt } from "jsencrypt";

function rsa_en(pubkey, target_str) {
    /** 
    分段加密信息
    
    :params target_str: 需要加密的信息，此处为很长的信息
    :pubkey: 公钥
    :return: 存储密文的数组
    **/
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubkey);
    let result = encrypt.encrypt(target_str);
    return result;
}

export {
    rsa_en
}