<template>
  <v-container>
    <v-row>
      <v-col cols="8" class="mx-auto">
        <v-card class="mx-auto my-12" max-width="400">
          <v-img
            src="../assets/poster.jpg"
            max-height="300"
            
          ></v-img>
          

          <v-card-title class="justify-center" v-if="vendor.name != null">
           {{ vendor.name }}
          </v-card-title>

          <v-card-subtitle class="text-center" v-if="vendor.description != null"> {{ vendor.description }} </v-card-subtitle>

          <v-card-actions>
            <v-btn color="primary" text @click="show = !show"> 关于 </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
              <v-icon>{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text><p v-for="line in vendor.introductions" :key="line">{{ line }}</p></v-card-text>
            </div>
          </v-expand-transition>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { vendor } from "/static/js/config.js";

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data: () => ({
      vendor: vendor,
      show: false,
    }),
};
</script>
