<template>
  <v-container>
    <div ref="ugc" v-html="content"></div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    content: '',
    js: null,
    my_js: null,
    
  }),

  methods: {
    get_post() {

      if (this.my_js != null) {
        this.$refs.ugc.removeChild(this.my_js);
        this.my_js = null;
      }

      axios
        .get("/backend/post/" + this.$route.params.app_id)
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            this.title = response.data.data.title;
            var content = JSON.parse(response.data.data.content);
            this.content = content.html;
            this.js = content.js;
            this.author = response.data.data.author;
            this.creation_time = response.data.data.creation_time.substr(0, 10);

            // 等到HTML渲染完毕后，再部署JS。以免出现在JS运行时找不到HTML控件的情况
            this.$nextTick(function() {
              const script = document.createElement('script');
              script.innerHTML = this.js;
              this.$refs.ugc.appendChild(script);
              this.my_js = script;
            });
          } else {
            console.log("获取标签失败");
          }
        });
    },
  },

  mounted() {
    this.get_post();
  },
};
</script>

<style scoped>

</style>