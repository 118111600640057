<template>
  <v-container>
    <v-col>
      <v-card elevation="10" width="600px" class="mx-auto text-center">
        <v-card-text>
            <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline
                :value="daily_ip_stats"
                color="black"
                height="150"
                padding="24"
                stroke-linecap="round"
                smooth
                >
                <template v-slot:label="item">
                    {{ item.value }} IP
                </template>
                </v-sparkline>
            </v-sheet>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
            <div class="text-h4 font-weight-thin">
                访客趋势
            </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      daily_ip_stats: [
        -1,
        -1,
        -1,
      ],
    };
  },
  methods: {
    get_daily_accesses() {
      axios.post("/backend/system/statistics/access", {}).then((response) => {
        var status = response.data.status;
        if (status == 0) {
          var items = response.data.data.items;
          this.daily_ip_stats = items;
        }
      });
    },
  },
  mounted() {
    this.get_daily_accesses();
  },
};
</script>

