<template>
  <v-container>
    <v-col>
      <v-card elevation="10" width="300px" class="mx-auto">
        <v-card-title>
          {{ user.name }}
        </v-card-title>
        <v-card-text>发布了 {{ user.post_num }} 篇文章</v-card-text>
        <v-card-text>
          <v-btn class="my-2" block dark @click="on_click_logout_button"
            >退出</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      user: {
        id: -1,
        name: "",
        post_num: 0,
      },
    };
  },
  methods: {
    on_click_logout_button() {
      axios.post("/backend/user/logout").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.$router.push({
            path: "/user/login",
          });
        }
      });
    },
    get_current_user_status() {
      axios.post("/backend/user/current").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.user.id = response.data.data.id;
          this.get_user_info(this.user.id);
        }
      });
    },
    get_user_info(user_id) {
      axios.post("/backend/user/info/" + user_id).then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.user.name = response.data.data.name;
          this.user.post_num = response.data.data.post_num;
        } else {
          this.user.post_num = '未知';
        }
      });
    },
  },
  mounted() {
    this.get_current_user_status();
  },
};
</script>

