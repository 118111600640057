<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="9" lg="10" md="11" sm="12" class="mx-auto">
        <v-row>
          <v-col class="mt-1">
            <v-card elevation="0" rounded="lg" class="mb-4">
              <v-card-title>{{ title }}</v-card-title>
              <v-card-subtitle
                >{{ author }} @ {{ creation_time }}</v-card-subtitle
              >
              <v-card-text class="post-content" v-html="formated_content"> </v-card-text>
            </v-card>
            <v-btn
              v-if="login_ok == true"
              x-small
              fab
              depressed
              link
              :to="'/blog/post/' + this.$route.params.post_id + '/edit'"
              color="secondary"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <div ref="ext"></div>
            <!-- <v-btn color="white" depressed class="float-right" link to="/blog/post/1/edit">编辑</v-btn> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["login_ok"],

  data: () => ({
    // login_ok: true,
    title: "",
    content: "",
    author: "",
    creation_time: "",

    my_js: null, // js holder

    // 跳转到页面内链接的内容，并且高亮显示
    js_to_highlight_original: "document.querySelectorAll('.post-content a').forEach(anchor => { anchor.addEventListener('click', function (e) { if(!this.getAttribute('href').startsWith('#')) { return; } e.preventDefault(); document.querySelectorAll('.original').forEach(original => { original.classList.remove('highlight'); }); const targetId = this.getAttribute('href').substring(1); const targetSection = document.getElementById(targetId); targetSection.scrollIntoView({ behavior: 'smooth' }); targetSection.classList.add('highlight'); setTimeout(() => { targetSection.classList.remove('highlight'); }, 2000); }); });",
  }),

  methods: {
    get_post() {
      if (this.my_js != null) {
        this.$refs.ext.removeChild(this.my_js);
        this.my_js = null;
      }

      axios
        .get("/backend/post/" + this.$route.params.post_id)
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            this.title = response.data.data.title;
            this.content = response.data.data.content;
            this.author = response.data.data.author;
            this.creation_time = response.data.data.creation_time.substr(0, 10);

            // 等到HTML渲染完毕后，再部署JS。以免出现在JS运行时找不到HTML控件的情况
            this.$nextTick(function() {
              const script = document.createElement('script');
              script.innerHTML = this.js_to_highlight_original;
              this.$refs.ext.appendChild(script);
              this.my_js = script;
            });
          } else {
            console.log("获取标签失败");
          }
        });
    },
  },

  mounted() {
    this.get_post();
  },

  computed: {
    formated_content() {
      if (this.content != null) {
        return this.content.replace(/\n/g, "<br/>");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.post-content >>> p,
span {
  max-width: 100%;
}

.post-content >>> img {
  max-width: 100%;
  border: 1px dashed grey;
  border-radius: 12px;
  padding: 2px;
  margin: 2px;
}

.post-content >>> pre {
  overflow:auto;
  background-color:whitesmoke;
  border-left:6px solid grey;
  padding: 10px;
}

.post-content >>> a {
  text-decoration: none;
}

.post-content >>> .original {
  transition: background-color 0.5s ease;
}

.post-content >>> .highlight {
  background-color: yellow;
  border: 1px dashed orange;
  padding-left: 4px;
  padding-right: 4px;
}
</style>