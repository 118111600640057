import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'typeface-roboto/index.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // 设置使用本地的icon资源
    }
});
