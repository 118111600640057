<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="9" lg="10" md="11" sm="12" class="mx-auto">
        <v-row>
          <v-col cols="12" xl="7" lg="7" md="12" sm="12" class="mt-1">
            <v-card elevation="0" rounded="lg" class="mb-4">
              <v-card-text>
                <v-text-field v-model="title" label="标题" dense required></v-text-field>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('h')">H1</v-btn>
                  </template>
                  <span>大标题</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('s')">H2</v-btn>
                  </template>
                  <span>小标题</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('b')">Bold</v-btn>
                  </template>
                  <span>粗体</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('c')">Code</v-btn>
                  </template>
                  <span>代码块</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('i')">LI</v-btn>
                  </template>
                  <span>列表项</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('u')">UL</v-btn>
                  </template>
                  <span>无序列表</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small outlined rounded text class="mr-2"
                      @click="on_selection('o')">OL</v-btn>
                  </template>
                  <span>有序列表</span>
                </v-tooltip>
                
                <v-divider vertical></v-divider>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small icon class="mr-2">
                      <v-file-input class="ml-2" prepend-icon="mdi-image" hide-input dense v-model="image_file" accept="image/*"
                        label="插入图片" @change="upload_image"></v-file-input>
                    </v-btn>
                  </template>
                  <span>图片</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small icon class="mr-2">
                      <v-file-input class="ml-2" prepend-icon="mdi-music" hide-input dense v-model="audio_file" accept="audio/*" label="插入音频"
                  @change="upload_audio"></v-file-input>
                    </v-btn>
                  </template>
                  <span>音频</span>
                </v-tooltip>

                <v-textarea class="mt-4" ref="content_textarea" rows="16" label="内容" v-model="content" v-on:paste="handle_paste"
                  @keyup.native.alt.66="on_selection('b')" @keyup.native.alt.72="on_selection('h')"
                  @keyup.native.alt.83="on_selection('s')" @keyup.native.alt.67="on_selection('c')"
                  @keyup.native.alt.73="on_selection('i')" @keyup.native.alt.85="on_selection('u')"
                  @keyup.native.alt.79="on_selection('o')">
                </v-textarea>

                <v-combobox v-model="selected_tags" :filter="filter" :hide-no-data="!search" :items="tags"
                  item-text="name" :search-input.sync="search" hide-selected label="标签" multiple small-chips>
                  <template v-slot:no-data>
                    <v-list-item>
                      <span class="subheading">Create</span>
                      <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
                        {{ search }}
                      </v-chip>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip v-if="item === Object(item)" v-bind="attrs" :color="`${item.color} lighten-3`"
                      :input-value="selected" label small>
                      <span class="pr-2">
                        {{ item.name }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ index, item }">
                    <v-text-field v-if="editing === item" v-model="editing.name" autofocus flat
                      background-color="transparent" hide-details solo @keyup.enter="edit(index, item)"></v-text-field>
                    <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
                      {{ item.name }}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-list-item-action @click.stop>
                      <v-btn icon @click.stop.prevent="edit(index, item)">
                        <v-icon>{{
                          editing !== item ? "mdi-pencil" : "mdi-check"
                          }}</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </template>
                </v-combobox>

                <v-select v-model="visibility" :items="visibilities" item-text="name" item-value="id" label="可见性"
                  return-object></v-select>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xl="5" lg="5" md="12" sm="12" class="mt-1">
            <v-card elevation="0" rounded="lg" class="mb-4">
              <v-card-text class="post-content" v-html="formated_content">
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="mt-1">
            <v-btn x-small fab depressed class="ml-2" color="secondary" link @click="on_create_post">
              <v-icon> mdi-check </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          处理中
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    title: "",
    content: "",

    // TAG功能
    selected_tags: [],
    tags: [
      // {
      //   id: 1,
      //   name: "hello",
      //   color: "blue",
      // },
      // {
      //   id: 2,
      //   name: "world",
      //   color: "red",
      // },
    ],
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    editingIndex: -1,
    nonce: 1,
    search: null,

    image_file: null, // 图片粘贴功能
    audio_file: null, // 音频粘贴功能
    dialog: false,

    // 可见性
    visibility: {
      id: 0,
      name: "对所有人公开",
    },
    visibilities: [
      {
        id: 0,
        name: "对所有人公开",
      },
      {
        id: 1,
        name: "对登录用户可见",
      },
      {
        id: 2,
        name: "仅对自己可见",
      },
    ],
  }),
  methods: {
    on_selection(action_name) {
      console.log("on_selection: " + action_name);
      let bodyTextArea =
        this.$refs.content_textarea.$el.querySelector("textarea");
      let start = bodyTextArea.selectionStart;
      let end = bodyTextArea.selectionEnd;
      var selection = "";
      if (start > -1 && end > start) {
        selection = this.content.substring(start, end).trim();
        // console.log("选中了：" + selection)
      } else {
        return;
      }
      let part_1 = this.content.substring(0, start);
      let part_2 = this.content.substring(end, this.content.length);
      if (action_name == "b") { // 加粗（bold）
        this.content = part_1 + "<b>" + selection + "</b>" + part_2;
      } else if (action_name == "h") { // 大标题（header）
        this.content = part_1 + "<h1>\n" + selection + "\n</h1>" + part_2;
      } else if (action_name == "s") { // 小标题（small）
        this.content = part_1 + "<h2>\n" + selection + "\n</h2>" + part_2;
      } else if (action_name == "c") { // 代码块（code）
        this.content =
          part_1 +
          "<pre>\n" +
          selection.replace(/</g, "&lt;").replace(/>/g, "&gt;") +
          "\n</pre>" +
          part_2;
      } else if (action_name == "i") { // 列表项（item）
        this.content = part_1 + "<li>" + selection + "</li>" + part_2;
      } else if (action_name == "u") { // 无序列表（unordered list）
        this.content = part_1 + "<ul>" + selection + "</ul>" + part_2;
      } else if (action_name == "o") { // 有序列表（ordered list）
        this.content = part_1 + "<ol>" + selection + "</ol>" + part_2;
      }
    },
    handle_paste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        console.log("当前浏览器不支持操作本地剪贴板");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        console.log("粘贴内容非图片");
        return;
      }
      // 此时file就是我们的剪切板中的图片对象
      // 如果需要预览，可以执行下面代码
      // const reader = new FileReader();
      // reader.onload = (event) => {
      //   preview.innerHTML = `<img src="${event.target.result}">`;
      // };
      // reader.readAsDataURL(file);
      this.image_file = file;

      this.upload_image();
    },

    upload_image() {
      let file = this.image_file;
      if (file == null || !file) {
        console.log("请选择图片后上传");
        return;
      }
      this.dialog = true;
      let form = new FormData();
      form.append("data", file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post("/backend/file/image/upload", form, config)
        .then((response) => {
          this.dialog = false;
          this.image_file = null;
          if (response.data.status == 0) {
            console.log("上传成功：" + response.data.data.item_id);
            this.content +=
              '<img src="/backend/file/image/' +
              response.data.data.item_id +
              '" />';
          } else {
            console.log("上传失败");
          }
        });
    },

    upload_audio() {
      let file = this.audio_file;
      if (file == null || !file) {
        console.log("请选择音频后上传");
        return;
      }
      this.dialog = true;
      let form = new FormData();
      form.append("data", file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post("/backend/file/audio/upload", form, config)
        .then((response) => {
          this.dialog = false;
          this.audio_file = null;
          if (response.data.status == 0) {
            console.log("上传成功：" + response.data.data.item_id);
            this.content +=
              '<audio controls><source src="/backend/file/audio/' +
              response.data.data.item_id +
              '" type="audio/mpeg">Audio</audio>';
          } else {
            console.log("上传失败");
          }
        });
    },

    get_post() {
      if (this.$route.params.post_id != undefined) {
        axios
          .get("/backend/post/" + this.$route.params.post_id)
          .then((response) => {
            var status = response.data.status;
            if (status == 0) {
              this.title = response.data.data.title;
              this.content = response.data.data.content;
              this.selected_tags = response.data.data.tags;
              this.visibility = this.visibilities[response.data.data.visibility];
            } else {
              console.log("获取文章失败");
            }
          });
      }
    },
    get_all_tags() {
      axios.get("/backend/tags/all").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.tags = response.data.data.tags;
        } else {
          console.log("获取标签失败");
        }
      });
    },
    on_create_post() {
      var body = {
        title: this.title,
        content: this.content,
        tags: this.selected_tags,
        visibility: this.visibility.id,
        thumb: null,
        type: 0,
      };
      if (this.$route.params.post_id != undefined) {
        body["post_id"] = parseInt(this.$route.params.post_id);
      }

      axios.post("/backend/post/create", body).then((response) => {
        var status = response.data.status;
        if (status == 0) {
          console.log("发布成功");
          this.$router.push({
            path: "/blog",
          });
        } else {
          console.log("发布失败");
        }
      });
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  watch: {
    selected_tags(val, prev) {
      if (val.length === prev.length) return;

      this.selected_tags = val.map((v) => {
        if (typeof v === "string") {
          v = {
            name: v,
            color: this.colors[this.nonce - 1],
          };

          this.tags.push(v);
          this.nonce++;
        }

        return v;
      });

      console.log(this.selected_tags);
    },
  },
  mounted() {
    this.get_post();
    this.get_all_tags();
  },
  computed: {
    formated_content() {
      if (this.content != null) {
        return this.content.replace(/\n/g, "<br/>");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.post-content>>>img,
p,
span {
  max-width: 100%;
}

.post-content>>>pre {
  overflow: auto;
  background-color: whitesmoke;
  border-left: 6px solid grey;
  padding: 10px;
}
</style>