<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="9" lg="10" md="11" sm="12" class="mx-auto">
        <v-row>
          <v-col cols="12" xl="7" lg="7" md="12" sm="12" class="mt-1">
            <v-card elevation="0" rounded="lg" class="mb-4">
              <v-card-text>
                <v-text-field
                  v-model="title"
                  label="应用名称"
                  dense
                  required
                ></v-text-field>

                <v-text-field
                  v-model="thumb"
                  label="缩略图"
                  dense
                  required
                ></v-text-field>

                <v-file-input
                  v-model="file"
                  show-size
                  accept="image/*"
                  label="插入图片"
                  @change="upload_image"
                ></v-file-input>

                <v-textarea
                  ref="html_content_textarea"
                  rows="16"
                  label="HTML"
                  v-model="html_content"
                  v-on:paste="handle_paste"
                >
                </v-textarea>

                <v-textarea
                  ref="js_content_textarea"
                  rows="16"
                  label="JavaScript"
                  v-model="js_content"
                  v-on:paste="handle_paste"
                >
                </v-textarea>

                <v-select
                  v-model="visibility"
                  :items="visibilities"
                  item-text="name"
                  item-value="id"
                  label="可见性"
                  return-object
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xl="5" lg="5" md="12" sm="12" class="mt-1">
            <v-card elevation="0" rounded="lg" class="mb-4">
              <v-card-text class="post-content" v-html="html_content">
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="mt-1">
            <v-btn
              x-small
              fab
              depressed
              class="ml-2"
              color="secondary"
              link
              @click="on_create_post"
            >
              <v-icon> mdi-check </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          处理中
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    title: "",
    thumb: "",
    html_content: "",
    js_content: "",

    // 图片粘贴功能
    file: null,
    dialog: false,

    // 可见性
    visibility: {
      id: 0,
      name: "对所有人公开",
    },
    visibilities: [
      {
        id: 0,
        name: "对所有人公开",
      },
      {
        id: 1,
        name: "对登录用户可见",
      },
      {
        id: 2,
        name: "仅对自己可见",
      },
    ],
  }),
  methods: {
    handle_paste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        console.log("当前浏览器不支持操作本地剪贴板");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        console.log("粘贴内容非图片");
        return;
      }
      // 此时file就是我们的剪切板中的图片对象
      // 如果需要预览，可以执行下面代码
      // const reader = new FileReader();
      // reader.onload = (event) => {
      //   preview.innerHTML = `<img src="${event.target.result}">`;
      // };
      // reader.readAsDataURL(file);
      this.file = file;

      this.upload_image();
    },

    upload_image() {
      let file = this.file;
      if (file == null || !file) {
        console.log("请粘贴图片后上传");
        return;
      }
      this.dialog = true;
      let form = new FormData();
      form.append("data", file);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post("/backend/file/image/upload", form, config)
        .then((response) => {
          this.dialog = false;
          this.file = null;
          if (response.data.status == 0) {
            console.log("上传成功：" + response.data.data.image_id);
            this.thumb +=
              '<img src="/backend/file/image/' +
              response.data.data.image_id +
              '" />';
          } else {
            console.log("上传失败");
          }
        });
    },

    get_post() {
      if (this.$route.params.app_id != undefined) {
        axios
          .get("/backend/post/" + this.$route.params.app_id)
          .then((response) => {
            var status = response.data.status;
            if (status == 0) {
              this.title = response.data.data.title;
              this.thumb = response.data.data.thumb;
              var content = JSON.parse(response.data.data.content);
              this.html_content = content.html;
              this.js_content = content.js;
              this.visibility = this.visibilities[response.data.data.visibility];
            } else {
              console.log("获取文章失败");
            }
          });
      }
    },
    on_create_post() {
      var content = JSON.stringify({
            html: this.html_content,
            js: this.js_content,
        });

      var body = {
        title: this.title,
        content: content,
        tags: null,
        visibility: this.visibility.id,
        thumb: this.thumb,
        type: 1,
      };
      if (this.$route.params.app_id != undefined) {
        body["post_id"] = parseInt(this.$route.params.app_id);
      }

      axios.post("/backend/post/create", body).then((response) => {
        var status = response.data.status;
        if (status == 0) {
          console.log("发布成功");
          this.$router.push({
            path: "/app",
          });
        } else {
          console.log("发布失败");
        }
      });
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  mounted() {
    this.get_post();
  },
};
</script>

<style scoped>
.post-content >>> img,
p,
span {
  max-width: 100%;
}

.post-content >>> pre {
  overflow: auto;
  background-color: whitesmoke;
  border-left: 6px solid grey;
  padding: 10px;
}
</style>