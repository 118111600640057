<template>
  <v-container>
    <v-app-bar app color="white" elevation="0">

<v-toolbar-title>应用程序</v-toolbar-title>

<!-- <v-spacer></v-spacer>

<v-btn v-bind="attrs" v-on="on" fab text x-small>
      <v-icon large color="primary">mdi-account-circle</v-icon>
    </v-btn> -->

</v-app-bar>



    <v-col>
      <v-card elevation="10" width="300px" class="mx-auto">
        <v-card-text>
          <v-text-field
            v-model="email"
            class="my-0"
            label="用户"
          ></v-text-field>
          <v-text-field
            v-model="code"
            class="my-0"
            type="password"
            label="密码"
          ></v-text-field>
          <v-btn class="my-2" block dark @click="on_click_login_button"
            >登录</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>


<script>
import { rsa_en } from "/static/js/utils/rsa.js";
import { vendor } from "/static/js/config.js";
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      code: "",
    };
  },
  methods: {
    rsa(pubkey, target_str) {
      return rsa_en(pubkey, target_str);
    },
    on_click_login_button() {
      let email_encoded = this.rsa(vendor.public_key, this.email);
      let code_encoded = this.rsa(vendor.public_key, this.code);

      axios
        .post("/backend/app/user/login", {
          email: email_encoded,
          code: code_encoded,
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            if (this.$route.params.target_app_name != undefined) {
              this.$router.push({
                path: "/app/" + this.$route.params.target_app_name,
              });
            } else {
              // 跳转到用户首页
              this.$router.push({
                path: "/app/user/home",
              });
            }

            
          }
        });
    },
  },
};
</script>

