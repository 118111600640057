<template>
  <v-container>

    <v-app-bar app color="white" elevation="0">

<v-toolbar-title>应用程序</v-toolbar-title>

<!-- <v-spacer></v-spacer>

<v-btn v-bind="attrs" v-on="on" fab text x-small>
      <v-icon large color="primary">mdi-account-circle</v-icon>
    </v-btn> -->

</v-app-bar>


    <v-col>
      <v-card elevation="10" width="300px" class="mx-auto">
        <v-card-title>
          {{ user.name }}
        </v-card-title>
        <v-card-subtitle>最近一次登录信息</v-card-subtitle>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>时间</v-list-item-title>
            <v-list-item-subtitle>{{ user.last_login.time }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>设备</v-list-item-title>
            <v-list-item-subtitle>{{ user.last_login.agent }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-text>
          <v-btn class="my-2" block dark @click="on_click_logout_button">退出</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      user: {
        id: -1,
        name: "",
        last_login: null,
      },
    };
  },
  methods: {
    on_click_logout_button() {
      axios.post("/backend/app/user/logout").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.$router.push({
            path: "/app/user/login",
          });
        }
      });
    },
    get_current_user_status() {
      axios.post("/backend/app/user/current").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          if (this.$route.params.target_app_name != undefined) {
            this.$router.push({
              path: "/app/" + this.$route.params.target_app_name,
            });
          } else {
            this.user.id = response.data.data.id;
            this.get_user_info(this.user.id);
          }
        } else {
          if (this.$route.params.target_app_name != undefined) {
            this.$router.push({
              path: "/app/user/login/" + this.$route.params.target_app_name,
            });
          } else {
            this.$router.push({
              path: "/app/user/login",
            });
          }
        }
      });
    },
    get_user_info(user_id) {
      axios.post("/backend/app/user/info/" + user_id).then((response) => {
        console.log(response.data)
        var status = response.data.status;
        if (status == 0) {
          this.user.name = response.data.data.name;
          this.user.last_login = response.data.data.last_login;
        } else {
          this.user.last_login = null;
        }
      });
    },
  },
  mounted() {
    this.get_current_user_status();
  },
};
</script>
