<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="8" lg="9" md="10" sm="12" class="mx-auto">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-text-field
              v-model="keywords"
              label="关键词"
              append-outer-icon="mdi-magnify"
              @click:append-outer="on_click_search"
              @keydown.enter="on_click_search"
            ></v-text-field>

            <v-btn
              v-if="login_ok == true"
              x-small
              class="mb-4"
              fab
              depressed
              color="secondary"
              link
              to="/app/new"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>

            <v-row class="mt-1">
              <v-col cols="4" v-for="item in posts" :key="item.id">
                <v-card
                  elevation="0"
                  rounded="lg"
                  class="mb-4"
                  link
                  :to="'/app/' + item.id"
                >
                  <v-card-title>{{ item.title }}</v-card-title>
                  <v-card-text class="post-content" v-html="item.thumb">
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="my-2 mx-2" v-if="show_page_info == true">
              <v-spacer></v-spacer>

              <v-btn
                x-small
                class="mb-4 ml-2"
                fab
                depressed
                plain
                @click="on_click_page_backward"
              >
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-chip class="mb-4 ml-2" outlined>
                第 {{ page_number }} / {{ total_page_number }} 页
              </v-chip>
              <v-btn
                x-small
                class="mb-4 ml-2"
                fab
                depressed
                plain
                @click="on_click_page_forward"
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["login_ok"],

  data: () => ({
    // login_ok: true,
    posts: [
      // {
      //   id: 1,
      //   title: "hello",
      //   content: "world",
      // },
    ],
    keywords: "", // 搜索关键词
    page_number: 1, // 当前显示的是第几页
    total_page_number: 1, // 总共有几页
    show_page_info: true, // 是否显示页码信息
  }),
  methods: {
    on_click_search() {
      this.show_page_info = true;
      axios
        .post("/backend/posts/select", {
          keywords: this.keywords,
          page_number: this.page_number,
          per_page: 9,
          type: 1,
        })
        .then((response) => {
          var status = response.data.status;
          if (status == 0) {
            this.posts = response.data.data.posts;
            this.page_number = response.data.data.page_number;
            this.total_page_number = response.data.data.total_page_number;
          } else {
            console.log("找不到文章");
            this.posts = [];
          }
        });
    },
    on_click_page_backward() {
      if (this.page_number > 1) {
        this.page_number -= 1;
      } else {
        this.page_number = 1;
      }
      this.on_click_search();
    },
    on_click_page_forward() {
      if (this.page_number < this.total_page_number) {
        this.page_number += 1;
      } else {
        this.page_number = this.total_page_number;
      }
      this.on_click_search();
    },
  },
  mounted() {
    this.on_click_search();
  },
};
</script>

<style scoped>
.post-content >>> img,
p,
span {
  max-width: 100%;
}

.post-content >>> pre {
  overflow: auto;
  background-color: whitesmoke;
  border-left: 6px solid grey;
  padding: 10px;
}
</style>