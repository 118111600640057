<template>
  <v-container>
    <v-app-bar app color="white" elevation="0">

      <v-toolbar-title>星星点灯 - 小组</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn fab text small color="red" @click="on_logout_clicked">
        <v-icon>mdi-logout</v-icon>
      </v-btn>


    </v-app-bar>

    <v-row>
      <v-col cols="12" xl="9" lg="10" md="11" sm="12" class="mx-auto">
        <v-row class="mt-2">
          <v-col class="mt-1">
            <v-card-title>{{ class_name }} {{ group_name }}</v-card-title>
            <v-card-subtitle>自 {{ start_time }} 开始统计</v-card-subtitle>
            <v-timeline dense>
              <v-timeline-item v-for="(item, index) in lesson_infos" :key="index" large>
                <template v-slot:icon>
                  <v-avatar>
                    <v-icon color="white">mdi-cat</v-icon>
                  </v-avatar>
                </template>
                <!-- <template v-slot:opposite>
                              <span>Tus eu perfecto</span>
                            </template> -->
                <v-card class="elevation-2">
                  <v-card-title class="text-h5">
                    <v-icon v-for="(item, index) in item.stars" :key="index" :color="item">mdi-star</v-icon>
                  </v-card-title>
                  <v-card-text>{{ item.lesson }} 获得 {{ item.scores }} 颗星星

                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>

            <v-card class="mx-auto mb-4" color="white" outline>
              <v-sparkline label-size="4" :value="scores" color="primary" line-width="2" padding="16"
                :smooth="radius || false">
              </v-sparkline>
            </v-card>


          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: [],

  data: () => ({
    group_name: '小组名称',
    start_time: '2024-01-01',
    radius: 10,
    lesson_infos: [
      {
        lesson: 'a',
        scores: 1,
        stars: [
          "red darken-1",
          "yellow darken-1",
        ],

      }
    ],
    scores: [
      200,
      675,
      410,
      390,
      310,
      460,
      250,
      240,
    ],

  }),

  methods: {
    on_logout_clicked() {
      axios.post("/backend/app/user/logout").then((response) => {
        var status = response.data.status;
        if (status == 0) {
          this.$router.push({
            path: "/app/user/login/stars",
          });
        }
      });
    },

    get_group_info() {
      axios
        .post("/backend/app/stars/group/get", {
          group_id: this.$route.params.group_id
        })
        .then((response) => {
          console.log(response.data);
          var status = response.data.status;
          if (status == 0) {
            var data = response.data.data;
            this.class_name = data.class_name;
            this.group_name = data.name;
            this.start_time = data.start_time;
            this.lesson_infos.splice(0, this.lesson_infos.length);
            var i = 0;
            for (i = 0; i < data.lesson_infos.length; i++) {
              var group = data.lesson_infos[i];
              var stars = [];
              for (var j = 0; j < group.scores; j++) {
                stars.push("yellow darken-1");
              }
              this.lesson_infos.push({
                lesson: group.lesson,
                scores: group.scores,
                stars: stars,
              });
            }
            this.scores.splice(0, this.scores.length);
            for (i = 0; i < data.scores.length; i++) {
              this.scores.push(data.scores[i]);
            }

          }
        });

    }
  },

  mounted() {
    this.get_group_info();
  },

  computed: {},
};
</script>

<style scoped>
.post-content>>>img,
p,
span {
  max-width: 100%;
}

.post-content>>>pre {
  overflow: auto;
  background-color: whitesmoke;
  border-left: 6px solid grey;
  padding: 10px;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>