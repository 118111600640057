module.exports = {
    // 站点信息
    vendor: {
        // 基础信息
        title: "ClassKit | 此时此课", // 网页<head><title>信息

        name: "ClassKit",
        description: "< 此时此课 >",
        introductions: [
            "欢迎光临！",
            "< 此时此课 > 分享一些实用的课堂小程序，让教学更轻松、愉快。",
            "一起探索教学的无限可能吧！"
        ],

        // 备案信息
        // icp_id: null, // 不显示备案信息
        icp_id: "闽ICP备16007595号-2",

        // 加密信息
        public_key: "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCsP6l+vrrdOSNKDYSq3LPUuKnm\n6QOI2GZPP09J5BUyr12z7WzxbHqAZVieZqhr5gKcX0J6V90po6095Qb+4DbKqyeY\nDAbyPCC5sr070ToTofFwV63JKdNLB05pOqAmyAkCqUdlwhX8TjX5dStE9Z4ZB3aI\nu2NnMdz/4ZWHDCfTbQIDAQAB\n-----END PUBLIC KEY-----",
    },

    // 技术供应商信息
    supplier: {
        // 产品名称
        capital: "此课",
        name: ""
    }
}