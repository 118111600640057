import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'

// 用户相关页面
import Home from './views/Home'
import Login from './views/user/Login'
import UserHome from './views/user/UserHome'
import AppLogin from './views/user/AppLogin'
import AppUserHome from './views/user/AppUserHome'

// 博客相关页面
import Blog from './views/blog/Blog'
import PostView from './views/blog/PostView'
import PostEdit from './views/blog/PostEdit'

// 应用相关页面
import AppIndex from './views/app/AppIndex'
import AppView from './views/app/AppView'
import AppEdit from './views/app/AppEdit'

// 星星点灯应用相关页面
import ClassCreator from './views/app/stars/ClassCreator'
import ClassBoard from './views/app/stars/ClassBoard'
import GroupBoard from './views/app/stars/GroupBoard'

// 系统相关页面
import Statistics from './views/system/Statistics'


Vue.config.productionTip = false

Vue.use(VueRouter)

const routes = [
  // 首页
  { path: '/', component: Home },
  { path: '/user/login', component: Login },
  { path: '/user/home', component: UserHome },

  // 博客
  { path: '/blog', component: Blog },
  { path: '/blog/post/new', component: PostEdit },
  { path: '/blog/post/:post_id', component: PostView },
  { path: '/blog/post/:post_id/edit', component: PostEdit },

  // 应用：Stars
  { path: '/app/stars/group/:group_id', component: GroupBoard },
  { path: '/app/stars/lesson/:lesson_id', component: ClassBoard },
  { path: '/app/stars', component: ClassCreator },

  // 应用
  { path: '/app/user/login', component: AppLogin },
  { path: '/app/user/login/:target_app_name', component: AppLogin },
  { path: '/app/user/home', component: AppUserHome },
  { path: '/app/user/home/:target_app_name', component: AppUserHome },
  { path: '/app', component: AppIndex },
  { path: '/app/new', component: AppEdit },
  { path: '/app/:app_id', component: AppView },
  { path: '/app/:app_id/edit', component: AppEdit },

  // 系统
  { path: '/system/statistics', component: Statistics },
]

const router = new VueRouter({
  mode: 'history',
  routes // （缩写）相当于 routes: routes
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
